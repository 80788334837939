import * as Dapp from "@elrondnetwork/dapp";

const devnet = false;

export const dAppName = "SRB Incubator";
export const decimals = 2;
export const denomination = 18;
export const gasPrice = 1000000000;
export const version = 1;
export const gasLimit = 50000;
export const gasPerDataByte = 1500;
export const walletConnectBridge = "https://bridge.walletconnect.org";
export const walletConnectDeepLink =
  "https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet.dev&link=https://maiar.com/";

export const contractAddress = devnet?
    "erd1qqqqqqqqqqqqqpgqp4s6nxzgjpqjr2arjdvp3vv9np3u4u6h6e5skzp9p7"
  : "erd1qqqqqqqqqqqqqpgqch4367q8vcjp4rne4nyqk8ng573pdg0nah2sps7xm7";
export const network: Dapp.NetworkType = devnet
  ? {
      id: "devnet",
      name: "Devnet",
      egldLabel: "xEGLD",
      walletAddress: "https://devnet-wallet.elrond.com",
      apiAddress: "https://devnet-api.elrond.com",
      gatewayAddress: "https://devnet-gateway.elrond.com",
      explorerAddress: "http://devnet-explorer.elrond.com/"
    }
  : {
      id: "mainnet",
      name: "Mainnet",
      egldLabel: "eGLD",
      walletAddress: "https://wallet.elrond.com/",
      apiAddress: "https://api.elrond.com",
      gatewayAddress: "https://gateway.elrond.com",
      explorerAddress: "https://explorer.elrond.com/"
    };
export const gatewayAddress = devnet
  ? "https://devnet-gateway.elrond.com"
  : "https://gateway.elrond.com";
export const apiAddress = devnet
  ? "https://devnet-api.elrond.com"
  : "https://api.elrond.com";
export const tokenDenomination = 1;
export const egldDenomination = 18;
export const tokenId = devnet ? "SOUL-41f8bb" : "SEED-b99bb5";
// "OITYT-3d8ac5";
// "BDHM-b186f7";
export const paymentTokenId = devnet ? "LKMEXICO-760044" : "LKMEX-aab910";
export const paymentTokenId2 = devnet ? "SOUL-41f8bb" : "SEED-b99bb5";

export const nftTokenId = devnet ? "FOSAS-6e946e" : "SEEDS-153f7a";
