import * as Dapp from "@elrondnetwork/dapp";

import {
  Card,
  GridContainerHeight,
} from "components/StyledComponents";

interface ILedger {
  callbackRoute: string;
  token?: string | undefined;
}

const StyledLedger = (props: ILedger) => {
  return (
    <GridContainerHeight h={"85vh"}>
      <Card path={"unlock"}>
        <Dapp.Pages.Ledger {...props} />
      </Card>
    </GridContainerHeight>
  );
};

export default StyledLedger;
