import * as React from "react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import styled from "styled-components";
import { padding } from "style.css";

import { color, fontSize, shadow1 } from "style.css";

const PageState = ({
  icon,
  iconClass,
  iconBgClass,
  iconSize = "5x",
  title,
  description,
  svgComponent,
  className,
  spin = false,
  style,
}: {
  icon?: IconProp;
  iconClass?: string;
  iconBgClass?: string;
  iconSize?: SizeProp;
  title?: string;
  description?: string | React.ReactNode;
  svgComponent?: React.ReactNode;
  className?: string;
  spin?: boolean;
  style?: Object;
}) => {
  return (
    <Container>
      <Content>
        {spin ? (
          <Content className="col-12 text-center" data-testid="loader">
            <Content className="lds-ellipsis mx-auto mt-5 mb-5">
              <div />
              <div />
              <div />
              <div />
            </Content>
          </Content>
        ) : (
          <ContentContainer>
            <Icon>{svgComponent}</Icon>
            <Content>{title && title}</Content>
            <Content>{description && <Content>{description}</Content>}</Content>
          </ContentContainer>
        )}
      </Content>
    </Container>
  );
};

export default PageState;

const Container = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  row-gap: ${padding.p3};
`;

const Icon = styled.div`
  height: auto;
`;
const Content = styled.div`
  max-width: 90vw;
  justify-self: center; 
  color: ${color.offWhite}
`;

const ContentContainer = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  row-gap: ${padding.p3};
`;
