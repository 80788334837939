import * as Dapp from "@elrondnetwork/dapp";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import PageNotFoud from "./components/PageNotFound";
import * as config from "./config";

import { ContextProvider } from "./context";
import routes, { routeNames } from "./routes";
import { GlobalStyle } from "style.css";
import StyledUnlock from "./pages/StyledUnlock";
import StyledWalletConnect from "pages/StyledWalletConnect";
import StyledLedger from "pages/StyledLedger";
import PresaleStatusProvider from "context/presaleStatusProvider";
import WithRedirect from "components/WithRedirect/WithRedirect";
import BalanceProvider from "context/balanceProvider";
import BalancesProvider from "context/balancesProvider";

export default function App() {
  return (
    <Router>
      <GlobalStyle />
      <Dapp.Context config={config}>
        <ContextProvider>
          <PresaleStatusProvider>
            <BalanceProvider>
              <BalancesProvider>
                <WithRedirect />
                <Layout>
                  <Switch>
                    <Route
                      path={routeNames.unlock}
                      component={() => (
                        <StyledUnlock
                          callbackRoute={routeNames.dashboard}
                          title={config.dAppName}
                          lead="Please select your login method:"
                          ledgerRoute={routeNames.ledger}
                          walletConnectRoute={routeNames.walletconnect}
                        />
                      )}
                      exact={true}
                    />
                    <Route
                      path={routeNames.ledger}
                      component={() => (
                        <StyledLedger callbackRoute={routeNames.dashboard} />
                      )}
                      exact={true}
                    />
                    <Route
                      path={routeNames.walletconnect}
                      component={() => (
                        <StyledWalletConnect
                          callbackRoute={routeNames.dashboard}
                          logoutRoute={routeNames.home}
                          title="Maiar Login"
                          lead="Scan the QR code using Maiar"
                        />
                      )}
                      exact={true}
                    />

                    {routes.map((route, i) => (
                      <Route
                        path={route.path}
                        key={route.path + i}
                        component={route.component}
                        exact={true}
                      />
                    ))}
                    <Route component={PageNotFoud} />
                  </Switch>
                </Layout>
              </BalancesProvider>
            </BalanceProvider>
          </PresaleStatusProvider>
        </ContextProvider>
      </Dapp.Context>
    </Router>
  );
}
