import PageState from "components/PageState";
import {
  CardStyle,
  GridContainerHeight,
  Spacer
} from "components/StyledComponents";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routeNames } from "routes";
import { Link } from "react-router-dom";
import * as Dapp from "@elrondnetwork/dapp";
import axios from "axios";
import { apiAddress, nftTokenId } from "config";
import { useEffect, useState } from "react";
import {
  decodeEncoded,
  decodeToUTF8
} from "pages/Dashboard/helpers/decodeResponse";

import { decodeUnsignedNumber } from "@elrondnetwork/erdjs/out";
import styled, { css, keyframes } from "styled-components";
import { color, fontSize, padding, shadow1 } from "style.css";

interface ISuccess {
  result: {
    status: string;
    txHash: string;
  };
}

export interface INft {
  attributes: string;
  collection: string;
  creator: string;
  identifier: string;
  isWhitelistedStorage: boolean;
  media: any[];
  name: string;
  nonce: number;
  owner: string;
  royalties: number;
  supply: string;
  ticker: string;
  timestamp: number;
  type: string;
  uris: string[];
  url: string;
  rarity: any;
  tokenIdentifier: string;
}

export const Success = ({ result }: ISuccess) => {
  const { txHash } = result;
  const { explorerAddress } = Dapp.useContext();
  const [nfts, setNfts] = useState<INft[]>([] as INft[]);
  const [nftIds, setNftIds] = useState<string[]>([] as string[]);
  const [endedLoading, setEndedLoading] = useState<number[]>([] as number[]);
  const fetchNFTs = async () => {
    const nftTokenIdBase =
      nftTokenId.split("-")[0] + "-" + nftTokenId.split("-")[1];

    try {
      const res = await axios.get(`${apiAddress}/transactions/${txHash}`);
      if (res.data.logs) {
        const events = res.data.logs.events;
        const ids = events.filter(
          (e: any) => e.identifier === "ESDTNFTTransfer"
        );
        const topics = ids.map((e: any) => e.topics);
        const nftTokens = [];
        let tokenId = "";
        for (let i = 0; i < topics.length; i++) {
          for (let j = 0; j < topics[i].length; j++) {
            const decoded = decodeToUTF8(topics[i][j]);
            if (decoded.includes(nftTokenIdBase)) {
              tokenId = decoded;
            }
            try {
              if (j === 1) {
                const nonce = decodeUnsignedNumber(decodeEncoded(topics[i][j]));
                let hex = nonce.toString(16);
                let padding = 4;
                while (hex.length < padding) {
                    if(hex[0] !== "0")
                    hex = "0" + hex;
                }
                const id =
                  tokenId + "-" + hex;
                nftTokens.push(id);
              }
            } catch (e) {}
          }
        }
        setNftIds(nftTokens);
      } else {
        console.log("empty response");
      }
    } catch (e) {
      console.log("failed fetching nfts", e);
    }
  };
  // useEffect(() => {
  //   fetchNFTs();
  // }, []);

  // useEffect(() => {
  //   slowFetch();
  // }, [nftIds]);

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  const sleeper = async () => {
    await sleep(10500);
  };
  const slowFetch = async () => {
    const fetchedNfts = [];
    for (let tokenIdentifier of nftIds) {
      await sleep(100);
      try {
        // console.log(`${apiAddress}/nft/${tokenIdentifier}`);
        const res2 = await axios.get(`${apiAddress}/nfts/${tokenIdentifier}`);
        const res3 = await axios.get(decodeToUTF8(res2.data.uris[1]));
        
        res2.data = {
          ...res2.data,
          tokenIdentifier
        };
        fetchedNfts.push(res2.data);
        setNfts([...fetchedNfts]);
      } catch (e) {
        console.log("error", e);
      }
    }
    setNfts(fetchedNfts);
  };

  const retryImg = async (e: any) => {
    await sleeper();
    e.target.src = e.target.src.split("?")[0] + "?" + new Date().getTime();
  };
  const endLoad = (idx: number) => {
    setEndedLoading(c => {
      return [...c, idx];
    });
  };
  return (
    <GridContainerHeight h={"85vh"}>
      <Spacer />
      <CardStyle backgroundColor={color.success}>
        <PageState
          svgComponent={
            <FontAwesomeIcon icon={faCheck} className="text-success fa-3x" />
          }
          style={{ justifyItems: "center" }}
          className="dapp-icon icon-medium"
          title="Transaction submitted successfully"
          description={
            <>
              <a
                style={{ maxWidth: "90vw" }}
                href={`${explorerAddress}transactions/${txHash}`}
                {...{
                  target: "_blank"
                }}
                className="tx-link"
                title="View in Explorer"
              >
                {txHash.slice(0, 5) + "..." + txHash.slice(-5, txHash.length)}
              </a>
              <br />

              <br />

              <Link to={routeNames.dashboard} className="btn btn-primary mt-3">
                Back to dashboard
              </Link>
            </>
          }
        />
        <Spacer />
        {/* <Header>Minted Just Now:</Header>
        <NFTResult>
          {nfts.length > 0 ? (
            nfts.map((e, idx) => {
              return (
                <NFTContainer
                  key={idx}
                  href={`${explorerAddress}nfts/${e.tokenIdentifier}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <NFTImage
                    src={e.url}
                    onError={retryImg}
                    onLoad={() => endLoad(idx)}
                    style={
                      endedLoading.includes(idx)
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  />
                  <Spinner />
                  <NFTName
                  >
                    {e.name}
                  </NFTName>
                </NFTContainer>
              );
            })
          ) : (
            <Spinner />
          )}
        </NFTResult> */}
      </CardStyle>
    </GridContainerHeight>
  );
};
export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  grid-column: 1 / end;
  align-self: center;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-self: center;
  position: absolute;
`;
export const NFTResult = styled.div`
  box-shadow: inset ${shadow1};
  height: 450px;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  column-gap: ${padding.p3};
  row-gap: ${padding.p3};
  overflow-y: scroll;
  background-color: ${color.primary};
`;
export const shineAnim = keyframes`
  0%,100%{
    margin-top: -700px;
    margin-left:-800px;
  }
  50%{
    margin-top: 700px;
    margin-left:800px;
  }
  `;
export const rotate = keyframes`
  0%,100%{
    transform: rotateY(0) rotateX(0);
  }
  50%{
    transform:  rotateY(-10deg) rotateX(-5deg);
  }
  `;
export const Shine = styled.div`
  width: 1270px;
  height: 180px;
  transform: rotate(30deg);
  /* margin-left: -100px; */
  background: -webkit-linear-gradient(
    top,
    transparent,
    rgba(255, 251, 226, 0.247),
    transparent
  );
  position: absolute;
  -webkit-animation: ${shineAnim} 8s ease-in-out infinite;
  z-index: 2000;
  justify-self: center;
`;

export const NFTContainer = styled.a<IRarity>`
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  width: 200px;
  height: 280px;
  margin: ${padding.p3};
  /* border: 3px solid ${color.button}; */
  background: ${color.offWhite};
  display: grid;
  justify-items: center;
  padding: ${padding.p3};
  border-radius: 10px;
  -webkit-box-shadow: ${shadow1};
  -moz-box-shadow: ${shadow1};
  box-shadow: ${shadow1};
  text-decoration: none;
  transition: 0.1s;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;

export const NFTImage = styled.img`
  width: 150px;
  height: 150px;
  z-index: 1;
`;
interface IRarity {
  fontSize?: string;
}
export const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);

  }
  
  20%, 80% {
    transform: translate3d(1px, 0, 0);

  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  
  }

  40%, 60% {
    transform: translate3d(1px, 0, 0);

  }
`;
export const NFTName = styled.div<IRarity>`
  font-size: ${fontSize.action};
  color: ${ color.fontDark};
`;

export const Header = styled.div`
  font-size: ${fontSize.header};
  font-weight: bold;
  padding-left: ${padding.p1};
  padding-bottom: ${padding.p1};
  color: ${color.offWhite}
`;

