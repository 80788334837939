import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import PageState from "components/PageState";
import { contractAddress } from "../../config";
import { useContext, useDispatch } from "../../context";
import Actions from "./Actions";
import { getTransactions } from "./helpers/asyncRequests";
import TopInfo from "./TopInfo";

import PageState from "../../components/PageState";
import { Container, GridContainer, Spacer } from "components/StyledComponents";
import { TransactionType } from "context/state";

import { NFTGallery } from "./NFTGallery";
import styled from "styled-components";
import { fontSize, padding } from "style.css";

const Dashboard = () => {
  const ref = React.useRef(null);
  const { apiAddress, address } = Dapp.useContext();
  const { transactionsFetched } = useContext();
  const dispatch = useDispatch();

  const fetchData = () => {
    getTransactions({
      apiAddress,
      address,
      timeout: 3000,
      contractAddress: contractAddress
    }).then(({ data, success }) => {
      // console.log("fetching", data);
      dispatch({
        type: "setTransactions",
        transactions: data as TransactionType[],
        transactionsFetched: success
      });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(fetchData, []);

  if (transactionsFetched === undefined) {
    return (
      <GridContainer>
        <PageState svgComponent={<></>} spin />
      </GridContainer>
    );
  }

  if (transactionsFetched === false) {
    return (
      <GridContainer>
        <PageState
          svgComponent={
            <FontAwesomeIcon icon={faBan} className="text-secondary fa-3x" />
          }
          title="Unavailable"
          className="dapp-icon icon-medium"
        />
      </GridContainer>
    );
  }

  return (
    <Container>
      <div className="container py-4" ref={ref}>
        <div className="row">
          <div className="col-12 col-md-10 mx-auto">
            <div className="card shadow-sm rounded border-0">
              <div className="card-body p-1">
                <div className="card rounded border-0 bg-primary">
                  <div className="card-body text-center p-4">
                    <TopInfo />
                    <Actions />
                  </div>
                </div>
                <Spacer />
                <Header>Your SEEDS NFT:</Header>
                <NFTGallery />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;

export const Header = styled.div`
  font-size: ${fontSize.header};
  font-weight: bold;
  padding-left: ${padding.p1};
  padding-bottom: ${padding.p1};
`;
