import axios from "axios";
import { TransactionType } from "context/state";

interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: "never",
          size: 25
        },
        timeout
      });
      const data2 = (
        await axios.get(`${apiAddress}${url}`, {
          params: {
            sender: address,
            receiver: address,
            condition: "never",
            size: 25
          },
          timeout
        })
      ).data;
      return {
        data: [...data, ...data2] as TransactionType[],
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getTransactions = fetchTransactions("/transactions");
export const getTransactionsCount = fetchTransactions("/transactions/count");

export const fetchESDT = async (url: string, address: string) => {
  try {
    const res = await axios.get(`${url}/${address}/esdt`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
};
