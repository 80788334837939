import PageState from "components/PageState";
import { CardStyle, GridContainerHeight } from "components/StyledComponents";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { routeNames } from "routes";
export const Fail = () => {
  return (
    <GridContainerHeight h={"85vh"}>
      <CardStyle style={{ justifySelf: "center" }}>
        <PageState
          style={{ justifySelf: "center", alignText: "center" }}
          svgComponent={
            <FontAwesomeIcon icon={faTimes} className="text-danger fa-3x" />
          }
          className="dapp-icon icon-medium"
          title="Error sending transaction"
          description={
            <>
              <p>Try again</p>
              <a href={routeNames.dashboard} className="btn btn-primary mt-3">
                Back to dashboard
              </a>
            </>
          }
        />
      </CardStyle>
    </GridContainerHeight>
  );
};
