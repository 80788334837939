import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import {
  Address,
  ContractFunction,
  Query,
  BytesValue,
  TypedValue,
  decodeUnsignedNumber
} from "@elrondnetwork/erdjs";

import {
  contractAddress,
  gatewayAddress,
  paymentTokenId,
  paymentTokenId2
} from "../../../config";
import { RawTransactionType } from "../../../helpers/types";
import useNewTransaction from "../../../pages/Transaction/useNewTransaction";
import { routeNames } from "../../../routes";
import { decodeEncoded, decodeToHex } from "../helpers/decodeResponse";
import { hexToDenominatedInt } from "../helpers/parseBigUInt";
import { CardStyle } from "components/StyledComponents";

import styled, { css, keyframes } from "styled-components";
import { color, dimensions, fontSize, padding, shadows } from "style.css";
import { IoMdRefresh } from "react-icons/io";

import { fetchESDT } from "../helpers/asyncRequests";
import {
  createESDTNFTTransaction,
  createESDTTransaction,
  transactionBuilder
} from "../helpers/transactionBuilders";
import denominate from "components/Denominate/denominate";
import { esdtReturnType, useBalance } from "context/balanceProvider";
import { useBalances } from "context/balancesProvider";
import gif from "../../../assets/img/SEEDS.gif";

interface IForm {
  token: string;
  value: string;
}
export const buildQuery = (
  func: ContractFunction,
  args: TypedValue[] | undefined
): Query => {
  const query = new Query({
    address: new Address(contractAddress),
    func,
    args
  });
  return query;
};
const Actions = () => {
  const [sendingValue, setSendingValue] = React.useState<IForm>({
    token: "0",
    value: "0"
  } as IForm);
  const sendTransaction = Dapp.useSendTransaction();
  const {
    dapp,
    address,
    account: { balance }
  } = Dapp.useContext();
  const newTransaction = useNewTransaction();
  const [amount, setAmount] = React.useState<number>(0);
  const [refresh, setRefresh] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [presaleStatus, setPresaleStatus] = React.useState<number>(0);
  const [maxMintPerTx, setMaxMintPerTx] = React.useState<number>(0);
  const [triggerWarn, setTriggerWarn] = React.useState<boolean>(false);
  const { selectedToken, setSelectedToken } = useBalance();
  const { tokens, setTokens } = useBalances();
  const [selectedTokenKey, setSelectedTokenKey] = React.useState(0);

  React.useEffect(() => {
    dapp.proxy
      .queryContract(buildQuery(new ContractFunction("getStatus"), []))
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const decoded = decodeUnsignedNumber(decodeEncoded(encoded));

        setPresaleStatus(decoded);
      })
      .catch(e => {
        console.log("vm response was too slow; getPresaleStatus", e);
      });

    dapp.proxy
      .queryContract(buildQuery(new ContractFunction("getIdVecLen"), []))
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const amount = decodeUnsignedNumber(decodeEncoded(encoded)); //1 decimal
        if (isNaN(amount)) {
          setAmount(0);
          // setLoading(false);
        } else {
          setAmount(amount);
        }
      })
      .catch(e => {
        console.log("vm response was too slow, getCurrentTokenBalance", e);
      });
    dapp.proxy
      .queryContract(buildQuery(new ContractFunction("getMaxMintPerTx"), []))
      .then(({ returnData }) => {
        const [encoded] = returnData;
        const amount = decodeUnsignedNumber(decodeEncoded(encoded)); //1 decimal
        if (isNaN(amount)) {
          setMaxMintPerTx(0);
          // setLoading(false);
        } else {
          setMaxMintPerTx(amount);
        }
      })
      .catch(e => {
        console.log("vm response was too slow, getCurrentTokenBalance", e);
      });
    const fetchESDTW = async () => {
      try {
        const esdts = await fetchESDT(`${gatewayAddress}/address`, address);
        if (esdts.data.esdts) {
          const arr: any = Object.entries(esdts.data.esdts).map(e => ({
            [e[0]]: e[1]
          }));
          const res = arr.filter((e: any) => {
            const ob: esdtReturnType = Object.values(e)[0] as esdtReturnType;
            // 18 decimal MEX
            if (ob) {
              if (ob.tokenIdentifier.includes(paymentTokenId2)) {
                ob.balance = denominate({
                  input: ob.balance,
                  denomination: 18,
                  decimals: 4,
                  showLastNonZeroDecimal: true
                });
              } else {
                ob.balance = denominate({
                  input: ob.balance,
                  denomination: 18,
                  decimals: 4,
                  showLastNonZeroDecimal: true
                });
              }

              return (
                ob.tokenIdentifier.includes(paymentTokenId) ||
                ob.tokenIdentifier.includes(paymentTokenId2)
              );
            } else {
              return false;
            }
          });

          const initialValue: esdtReturnType = {
            balance: denominate({
              input: balance,
              denomination: 18,
              decimals: 4,
              showLastNonZeroDecimal: false
            }),
            tokenIdentifier: "EGLD",
            name: "eGLD",
            price: 99000000000000000,
            denominatedPrice: 0
          };
          const m = [initialValue, ...res.map((e: any) => Object.values(e)[0])];
          const promises: Promise<any>[] = [];
          for (let i = 0; i < m.length; i++) {
            const token_ = m[i];
            let ticker = token_.tokenIdentifier;
            if (token_.nonce) {
              const tk = token_.tokenIdentifier.split("-");
              ticker = tk[0] + "-" + tk[1];
            }
            promises.push(
              dapp.proxy
                .queryContract(
                  buildQuery(new ContractFunction("getPrice"), [
                    BytesValue.fromUTF8(ticker)
                  ])
                )
                .then(({ returnData }) => {
                  const [encoded] = returnData;
                  const price = decodeUnsignedNumber(decodeEncoded(encoded));
                  token_.price = price;
                  token_.denominatedPrice = hexToDenominatedInt(
                    decodeToHex(encoded),
                    18
                  );
                })
                .catch(e => {
                  console.log("vm response was too slow, getPrice", e);
                })
            );
          }
          await Promise.all(promises);
          const fin = m.filter(n => {
            return n.price !== 0;
          });

          setTokens(fin);
          if (!fin[0]) {
            setSelectedToken(initialValue);
          } else {
            setSelectedToken(fin[0]);
          }
          setSendingValue(c => ({
            token: c.token,
            value: BigInt(
              parseFloat(c.token) * (m[0].price ? m[0].price : 0)
            ).toString() //denomination will be applied in later stage
          }));
          setSelectedTokenKey(0);
          setLoading(false);
        }
      } catch (e) {
        console.log("account probably does not exist", e);
      }
    };

    fetchESDTW();
  }, [refresh]);

  const handleChange = (e: any) => {
    setTriggerWarn(false);
    if (parseFloat(e.target.value) > maxMintPerTx) {
      setTriggerWarn(true);
      setSendingValue({
        token: maxMintPerTx.toString(),
        value: BigInt(
          maxMintPerTx * (selectedToken.price ? selectedToken.price : 0)
        ).toString()
      });
    } else {
      if (e.target.value === "" || parseFloat(e.target.value) < 0) {
        setSendingValue({ token: "", value: "" });
      } else if (
        !e.target.value.includes("e") &&
        !e.target.value.includes(" ")
      ) {
        setSendingValue({
          token: Math.floor(e.target.value).toString(),
          value: BigInt(
            parseFloat(e.target.value) *
              (selectedToken.price ? selectedToken.price : 0)
          ).toString()
        });
      }
    }
  };

  const handleRefresh = (e: any) => {
    if (!loading) {
      setLoading(true);
      e.preventDefault();
      setRefresh(c => {
        return (c += 1);
      });
    }
  };

  const handleSelect = (e: any) => {
    const a = tokens.filter(ele => ele.tokenIdentifier === e.target.value);
    setSelectedToken(a[0]);
    setSelectedTokenKey(e.target.key);
    setSendingValue(c => ({
      token: c.token,
      value: BigInt(
        parseFloat(c.token) * (a[0].price ? a[0].price : 0)
      ).toString() //denomination will be applied in later stage
    }));
  };
  const send = (tx: RawTransactionType) => {
    sendTransaction({
      transaction: newTransaction(tx),
      callbackRoute: routeNames.transaction
    });
  };
  const onSend = (e: any) => {
    e.preventDefault();
    selectedToken.tokenIdentifier === "EGLD"
      ? send(
          transactionBuilder(
            contractAddress,
            parseFloat(sendingValue.value),
            "mintNft",
            600000000
          )
        )
      : selectedToken.nonce
      ? send(
          createESDTNFTTransaction(
            contractAddress,
            address,
            parseFloat(sendingValue.value),
            "mintNft",
            600000000,
            selectedToken.tokenIdentifier,
            selectedToken.nonce
          )
        )
      : send(
          createESDTTransaction(
            contractAddress,
            parseFloat(sendingValue.value),
            selectedToken.tokenIdentifier,
            "mintNft",
            600000000
          )
        );
  };

  return (
    <ActionContainer>
      <CardStyleWidth>
        <CardContainer>
          <Card>
            <MintGifContainer>
              <MintGif src={gif} />
            </MintGifContainer>
            <DropdownMenuLabel></DropdownMenuLabel>
            <DropdownMenuLabel>Pay with: </DropdownMenuLabel>
            <DropdownMenu onChange={handleSelect} value={selectedTokenKey}>
              {tokens.map((e, idx) => {
                return (
                  <Option key={idx} value={e.tokenIdentifier}>
                    {e.tokenIdentifier.split("-")[0]} (balance: {e.balance})
                  </Option>
                );
              })}
            </DropdownMenu>
            <RefreshIcon onClick={handleRefresh} spin={loading ? true : false}>
              <IoMdRefresh size={fontSize.action} />
            </RefreshIcon>
            <Info
              label="price per NFT "
              content={
                loading
                  ? "loading..."
                  : `${
                      selectedToken?.denominatedPrice &&
                      selectedToken.tokenIdentifier === paymentTokenId2
                        ? selectedToken?.denominatedPrice
                        : selectedToken.denominatedPrice?.toFixed(3)
                    }` +
                    " " +
                    selectedToken.tokenIdentifier.split("-")[0]
              }
              presaleStatus={presaleStatus}
            />
            <br />
            <Info
              label="remaining"
              content={loading ? "loading..." : amount + " NFTs"}
              presaleStatus={presaleStatus}
            />

            <Info
              label="cost"
              content={
                selectedToken
                  ? denominate({
                      input: sendingValue.value,
                      denomination: selectedToken.tokenIdentifier.includes(
                        paymentTokenId2
                      )
                        ? 18
                        : 18,
                      decimals: 4,
                      showLastNonZeroDecimal: true
                    }) +
                    " " +
                    selectedToken.tokenIdentifier.split("-")[0]
                  : ""
              }
              presaleStatus={presaleStatus}
            />
            <Info
              label="max mint per tx"
              content={loading ? "loading..." : maxMintPerTx + " NFTs"}
              presaleStatus={presaleStatus}
              warn={triggerWarn}
            />
            <Form>
              <InputInfo>
                <Input
                  type="number"
                  placeholder="1"
                  value={sendingValue.token}
                  onChange={handleChange}
                  name="token"
                />
                <InputLabel>SEEDS NFT</InputLabel>
              </InputInfo>
              {presaleStatus === 1 ? (
                sendingValue.token !== "0" && sendingValue.value !== "0" ? (
                  <Button onClick={onSend}>mint</Button>
                ) : (
                  <DisabledButton
                    onClick={e => {
                      e.preventDefault();
                    }}
                  >
                    enter value
                  </DisabledButton>
                )
              ) : (
                <DisabledButton onClick={() => {}}>
                  minting ended
                </DisabledButton>
              )}
            </Form>
          </Card>
        </CardContainer>
      </CardStyleWidth>
    </ActionContainer>
  );
};

export default Actions;

const MintGifContainer = styled.div`
  grid-row: 1 / end;
  grid-column: 1 / end;
  justify-self: center;
  max-width: ${Math.floor(dimensions.cardWidth * 0.7)}px;
  @media only screen and (min-width: 768px) {
    max-width: ${Math.floor(dimensions.cardWidth * 0.7)}px;
  }
`;

const MintGif = styled.img`
  max-width: 200px;
  @media only screen and (min-width: 768px) {
    max-width: ${Math.floor(dimensions.cardWidth * 0.7 * 0.8)}px;
  }
  border: 5px solid ${color.backgroundBright};
`;
const DropdownMenuLabel = styled.div`
  grid-column: 1 / end;
  width: 100%;
  font-size: ${fontSize.mobile};
  color: ${color.fontWashed};
  font-weight: bold;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
  }
`;
const DropdownMenu = styled.select`
  grid-column: 1 / end;
  height: 30px;
  position: relative;
  outline: none;
  border: 1px solid #cdcdcd;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: white;
  /* width: 45vw; */
  color: black;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: ${Math.floor(dimensions.cardWidth * 0.5)}px;
    justify-self: center;
    height: 35px;
  }
`;

const Option = styled.option`
text-align:center;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: ${Math.floor(dimensions.cardWidth * 0.5)}px;
    /* padding: ${padding.p3}; */
  }
`;
const Info = (props: any) => {
  return (
    <>
      <Label warn={props.warn}>{props.label}</Label>
      <Content warn={props.warn}>
        {props.presaleStatus === 0 ? "minting ended" : props.content}
      </Content>
    </>
  );
};
const ActionContainer = styled.div`
  display: grid;
  width: 100%;
  @media only screen and (min-width: 768px) {
    justify-items: center;
    width: auto;
  }
`;

const CardStyleWidth = styled(CardStyle)`
  /* border-radius: 15px; */
  box-shadow: ${shadows.hard};
  background-color: ${color.cardBackground};
  text-align: center;
  width: 100%;
  max-width: ${dimensions.cardWidth}px;
  justify-self: center;
  font-size: ${fontSize.semiAction};
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: 100%;
    max-width: ${dimensions.cardWidth}px;
  }
`;
const CardContainer = styled.div`
  display: grid;
  width: 100%;
  padding: ${padding.p3} 0 ${padding.p3} 0;
  justify-items: center;
  @media only screen and (min-width: 768px) {
    justify-self: center;
    width: auto;
    max-width: ${dimensions.cardWidth}px;
  }
`;
const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${padding.p2};
  width: 100%;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: ${Math.floor(dimensions.cardWidth * 0.7)}px;
    justify-self: center;
    padding: ${padding.p3};
    row-gap: ${padding.p3};
  }
`;
const shakeAnim = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
    color:#e44545
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
    color:#e44545
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
    color:#e44545
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
    color:#e44545
  }
  100%{
    color: ${color.fontDark}
  }
`;

interface IWarn {
  warn?: boolean;
}
const Label = styled.div<IWarn>`
  grid-column: 1/2;
  justify-self: start;
  font-size: ${fontSize.mobile};
  color: ${color.fontWashed};
  font-weight: bold;
  animation: ${({ warn }) =>
      warn
        ? css`
            ${shakeAnim}
          `
        : null}
    0.5s linear;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
  }
`;

const Content = styled.div<IWarn>`
  grid-column: 2/3;
  justify-self: end;
  font-size: ${fontSize.semiAction};
  color: ${color.fontDark};
  font-weight: bold;
  animation: ${({ warn }) =>
      warn
        ? css`
            ${shakeAnim}
          `
        : null}
    0.5s linear;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
  }
`;

export const spinAnim = keyframes`
 from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;
interface IRefreshIcon {
  spin: boolean;
}

export const RefreshIcon = styled.div<IRefreshIcon>`
  grid-column: 2/3;
  justify-self: end;
  &:hover {
    cursor: pointer;
  }
  animation: ${({ spin }) =>
      spin
        ? css`
            ${spinAnim}
          `
        : null}
    0.2s infinite linear;
`;

const Form = styled.form`
  grid-column: 1 / end;
  display: grid;
  justify-items: center;
  align-items: center;
  row-gap: ${padding.p3};
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-self: center;
`;
const InputInfo = styled.div`
  position: relative;
  grid-column: 1/2;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  column-gap: ${padding.p2};
  width: 100%;
`;
const InputLabel = styled.label`
  position: absolute;
  justify-self: end;
  padding: ${padding.p2};
  color: ${color.fontWashed};
`;

const Input = styled.input`
  grid-column: 1 / 2;
  font-size: ${fontSize.semiAction};
  padding: ${padding.p1};
  height: 30px;
  position: relative;
  outline: none;
  border: 1px solid #cdcdcd;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: white;
  width: 45vw;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  --moz-appearance: textfield;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.desktop};
    width: ${Math.floor(dimensions.cardWidth * 0.5)}px;
    justify-self: center;
    padding: ${padding.p3};
  }
`;
const Button = styled.button`
  grid-column: 1 / end;
  display: grid;
  text-decoration: none;
  font-weight: bold;
  font-size: ${fontSize.action};
  color: ${color.offWhite};
  box-shadow: ${shadows.semiHard};
  padding: 0 ${padding.p3} 0 ${padding.p3};
  border-radius: 15px;
  background-color: ${color.button};
  &:visited {
    color: ${color.button};
  }
  &:hover {
    cursor: pointer;
  }
  &:active {
    box-shadow: ${shadows.semiHardActive};
  }
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  border: none;
  outline: none;

  &:hover {
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    cursor: pointer;
    color: ${color.button};
  }

  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: 0;
  }
`;

const DisabledButton = styled(Button)`
  grid-column: 1 / end;
  display: grid;
  text-decoration: none;
  font-weight: bold;
  font-size: ${fontSize.action};
  color: ${color.button};
  box-shadow: ${shadows.semiHardActive};
  padding: 0 ${padding.p3} 0 ${padding.p3};
  border-radius: 15px;
  background-color: transparent;
  &:visited {
    color: ${color.button};
  }

  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  border: none;
  outline: none;

  &:hover {
    box-shadow: ${shadows.semiHardActive};
    cursor: default;
  }

  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: 0;
  }
`;
