import { useLocation } from "react-router-dom";

import { Fail } from "./Fail";
import { Success } from "./Success";

const Transaction = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { status, txHash } = Object.fromEntries(query);

  return status === "success" ? (
    <Success
      result={{
        status,
        txHash
      }}
    />
  ) : (
    <Fail />
  );
};

export default Transaction;
