import { paymentTokenId2 } from "config";
import BigNumber from "bignumber.js";
import {
  Address,
  AddressValue,
  BigUIntValue,
  BytesValue,
  ContractFunction,
  TransactionPayload,
  U64Value
} from "@elrondnetwork/erdjs/out";

export const createESDTNFTTransaction = (
  contract: string,
  address: string,
  amount: number,
  functionName: string,
  gas: number,
  tokenId?: string,
  tokenNonce?: number
) => {
  const encodedAmount = new BigNumber(amount, 10);
  const encodedNonce = new BigNumber(tokenNonce || 0, 10);

  const tickerSplit = tokenId ? tokenId.split("-") : ["", ""];

  const ticker = tickerSplit[0] + "-" + tickerSplit[1];

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTNFTTransfer"))
    .addArg(BytesValue.fromUTF8(ticker))
    .addArg(new U64Value(encodedNonce))
    .addArg(new BigUIntValue(encodedAmount))
    .addArg(new AddressValue(new Address(contract)))
    .addArg(BytesValue.fromUTF8(functionName))
    .build();

  return {
    receiver: address,
    data: payload.toString(),
    value: "0",
    gasLimit: gas
  };
};

export const transactionBuilder = (
  contract: string,
  value: number,
  functionName: string,
  gas: number
) => {
  return {
    receiver: contract,
    data: functionName,
    value: `${(value * 10 ** -18).toFixed(6)}`,
    gasLimit: gas
  };
};

export const createESDTTransaction = (
  contract: string,
  amount: number,
  tokenId: string,
  functionName: string,
  gas: number
) => {
  const encodedAmount =
    tokenId === paymentTokenId2
      ? new BigNumber(amount, 10)
      : new BigNumber(amount, 10);

  let payload = TransactionPayload.contractCall()
    .setFunction(new ContractFunction("ESDTTransfer"))
    .addArg(BytesValue.fromUTF8(tokenId))
    .addArg(new BigUIntValue(encodedAmount))
    .addArg(BytesValue.fromUTF8(functionName))
    .build();

  return {
    receiver: contract,
    data: payload.toString(),
    value: "0",
    gasLimit: gas
  };
};
