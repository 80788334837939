import * as React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import {
  contractAddress,
  gatewayAddress,
  paymentTokenId,
  paymentTokenId2
} from "../../config";

import styled from "styled-components";
import { color, fontSize } from "style.css";
import { buildQuery, RefreshIcon } from "./Actions";
import { IoMdRefresh } from "react-icons/io";
import { esdtReturnType, useBalance } from "context/balanceProvider";
import { fetchESDT } from "./helpers/asyncRequests";
import denominate from "components/Denominate/denominate";

import {
  BytesValue,
  ContractFunction,
  decodeUnsignedNumber
} from "@elrondnetwork/erdjs/out";
import { decodeEncoded, decodeToHex } from "./helpers/decodeResponse";
import { hexToDenominatedInt } from "./helpers/parseBigUInt";
import { useBalances } from "context/balancesProvider";
const TopInfo = () => {
  const {
    dapp,
    address,
    account: { balance }
  } = Dapp.useContext();
  const refresh = Dapp.useRefreshAccount();

  const [loading, setLoading] = React.useState<boolean>();
  const { selectedToken, setSelectedToken } = useBalance();
  const { tokens, setTokens } = useBalances();
  const [lkmexBalance, setLkmexBalance] = React.useState<string>("0");

  const fetchBalance = async () => {
    setLoading(true);
    // const res = await axios.get(`${apiAddress}/address/${address}/esdt`);
    // if (res.data.data.esdts[tokenId]) {
    //   setTokenBalance(parseFloat(res.data.data.esdts[tokenId].balance));
    // }
    const fetchESDTW = async () => {
      try {
        const esdts = await fetchESDT(`${gatewayAddress}/address`, address);
        let lkb = 0;
        if (esdts.data.esdts) {
          const arr: any = Object.entries(esdts.data.esdts).map(e => ({
            [e[0]]: e[1]
          }));
          const res = arr.filter((e: any) => {
            const ob: esdtReturnType = Object.values(e)[0] as esdtReturnType;
            // 18 decimal MEX
            if (ob) {
              if (ob.tokenIdentifier.includes(paymentTokenId)) {
                lkb += parseFloat(ob.balance);
              }
              if (ob.tokenIdentifier.includes(paymentTokenId2)) {
                ob.balance = denominate({
                  input: ob.balance,
                  denomination: 18,
                  decimals: 4,
                  showLastNonZeroDecimal: true
                });
              } else {
                ob.balance = denominate({
                  input: ob.balance,
                  denomination: 18,
                  decimals: 4,
                  showLastNonZeroDecimal: true
                });
              }
              return (
                ob.tokenIdentifier.includes(paymentTokenId) ||
                ob.tokenIdentifier.includes(paymentTokenId2)
              );
            } else {
              return false;
            }
          });

          const initialValue: esdtReturnType = {
            balance: denominate({
              input: balance,
              denomination: 18,
              decimals: 4,
              showLastNonZeroDecimal: false
            }),
            tokenIdentifier: "EGLD",
            name: "eGLD",
            price: 0,
            denominatedPrice: 0
          };
          const m = [initialValue, ...res.map((e: any) => Object.values(e)[0])];
          const promises: Promise<any>[] = [];
          for (let i = 0; i < m.length; i++) {
            const token_ = m[i];
            let ticker = token_.tokenIdentifier;
            if (token_.nonce) {
              const tk = token_.tokenIdentifier.split("-");
              ticker = tk[0] + "-" + tk[1];
            }
            promises.push(
              dapp.proxy
                .queryContract(
                  buildQuery(new ContractFunction("getPrice"), [
                    BytesValue.fromUTF8(ticker)
                  ])
                )
                .then(({ returnData }) => {
                  const [encoded] = returnData;
                  const price = decodeUnsignedNumber(decodeEncoded(encoded));
                  token_.price = price;
                  token_.denominatedPrice = hexToDenominatedInt(
                    decodeToHex(encoded),
                    18
                  );
                })
                .catch(e => {
                  console.log("vm response was too slow, getPrice", e);
                })
            );
          }
          if (promises.length > 0) await Promise.all(promises);
          const lkbBigNumber = BigInt(lkb);
          const lkbDenominated = denominate({
            input: lkbBigNumber.toString(),
            denomination: 18,
            decimals: 4,
            showLastNonZeroDecimal: false
          });
          const fin = m.filter(n => {
            return n.price !== 0 || n.tokenIdentifier === "EGLD";
          });
          setLkmexBalance(lkbDenominated);
          setSelectedToken(fin[0]);
          setTokens(fin);
          setLoading(false);
        }
      } catch (e) {
        console.log("account probably does not exist", e);
      }
    };
    fetchESDTW();
  };
  React.useEffect(() => {
    fetchBalance();
  }, []);
  const handleRefresh = () => {
    fetchBalance();
    refresh();
  };
  return (
    <div className="text-white" data-testid="topInfo">
      <div className="mb-4">
        <span className="opacity-6 mr-1">Contract address:</span>
        <span data-testid="contractAddress">{contractAddress}</span>
      </div>
      <div>
        <Title>Your Balance:</Title>

        <InfoContainer>
          <Label></Label>
          <Balance>
            <RefreshIcon onClick={handleRefresh} spin={loading ? true : false}>
              <IoMdRefresh size={fontSize.action} color={"white"} />
            </RefreshIcon>
          </Balance>
          {tokens.map((e, idx) => {
            if (e.tokenIdentifier.includes(paymentTokenId)) {
            } else {
              return (
                <React.Fragment key={idx}>
                  <Label>{e.tokenIdentifier.split("-")[0]}: </Label>
                  <Balance>
                    {loading
                      ? "..."
                      : e.balance.length > 18
                      ? e.balance.slice(0, 15) + ".."
                      : e.balance}
                  </Balance>
                </React.Fragment>
              );
            }
          })}

          {tokens.find(
            e => e.tokenIdentifier.includes(paymentTokenId),
            true
          ) === undefined ? null : (
            <>
              <Label>{paymentTokenId.split("-")[0]}: </Label>
              <Balance>
                {loading
                  ? "..."
                  : lkmexBalance.length > 15
                  ? lkmexBalance.slice(0, 15) + ".."
                  : lkmexBalance}
              </Balance>
            </>
          )}
        </InfoContainer>
        {/* 
        <InfoContainer>
          <Label>RARE: </Label>
          <Balance>{loading ? "..." : tokenBalance * 0.1}</Balance>
        </InfoContainer> */}
      </div>
    </div>
  );
};

export default TopInfo;
const Title = styled.div`
  font-size: ${fontSize.mobile};
  color: ${color.fontOpacity6};
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.header};
  }
`;
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;
const Label = styled.div`
  font-weight: light;
  font-size: ${fontSize.semiAction};
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.header};
  }
`;

const Balance = styled.div`
  font-size: ${fontSize.semiAction};
  font-weight: bold;
  @media only screen and (min-width: 768px) {
    font-size: ${fontSize.header};
  }
`;
