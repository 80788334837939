import * as Dapp from "@elrondnetwork/dapp";
import {
  Card,
  GridContainerHeight,

} from "components/StyledComponents";

interface IWalletConnect {
  title?: string | undefined;
  lead?: string | undefined;
  callbackRoute: string;
  logoutRoute: string;
  token?: string | undefined;
}

const StyledWalletConnect = (props: IWalletConnect) => {
  return (
    <GridContainerHeight h={"85vh"}>
      <Card path={"unlock"}>
        <Dapp.Pages.WalletConnect {...props} />
      </Card>
    </GridContainerHeight>
  );
};

export default StyledWalletConnect;
