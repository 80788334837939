import { apiAddress, nftTokenId } from "config";
import { useEffect, useState } from "react";
import * as Dapp from "@elrondnetwork/dapp";
import axios from "axios";
import {
  INft,
  NFTContainer,
  NFTImage,
  NFTName,
  NFTResult,
  Shine,
  Spinner
} from "pages/Transaction/Success";
import { decodeToUTF8 } from "../helpers/decodeResponse";

import styled from "styled-components";
import { fontSize } from "style.css";
export const NFTGallery = () => {
  const { address, explorerAddress } = Dapp.useContext();
  const [nfts, setNfts] = useState<INft[]>([] as INft[]);
  const [nftWithoutJson, setNftWithoutJson] = useState<INft[]>([] as INft[]);
  const [notEmpty, setNotEmpty] = useState<boolean>(false);
  const [endedLoading, setEndedLoading] = useState<number[]>([] as number[]);
  const fetchNFTs = async () => {
    const res = await axios.get(`${apiAddress}/accounts/${address}/nfts`, {
      params: {
        search: nftTokenId,
        size: 1000
      }
    });
    if (res.data.length > 0) {
      setNotEmpty(true);
      setNftWithoutJson(res.data);
    } else {
      console.log(res.data.length);
    }
  };
  useEffect(() => {
    fetchNFTs();
  }, []);

  useEffect(() => {
    slowFetch();
  }, [nftWithoutJson]);

  const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  const sleeper = async () => {
    await sleep(10500);
  };
  const slowFetch = async () => {
    const fetchedNfts = [];
    for (let nft of nftWithoutJson) {
      await sleep(100);
      try {
        const res3 = await axios.get(decodeToUTF8(nft.uris[1]));
        let ultra = false;
        let superultra = false;
        for (let p = 0; p < res3.data.attributes.length; p++) {
          if (res3.data.attributes[p].trait_type === "Badge") {
            ultra = true;
          } else if (
            res3.data.attributes[p].trait_type === "The_Ultimate_Ultra"
          ) {
            superultra = true;
          } else {
            ultra = false;
          }
        }
        if (ultra) {
          nft.rarity = {
            rarity: res3.data.rarity,
            type: "Ultra Rare Bear",
            name: res3.data.attributes.filter((e: any) => {
              return e.trait_type === "Ultra_Rare_Bear";
            })[0].value
          };
        } else if (superultra) {
          nft.rarity = {
            rarity: res3.data.rarity,
            type: "The Ultimate Ultra",
            name: res3.data.attributes.filter((e: any) => {
              return e.trait_type === "The_Ultimate_Ultra";
            })[0].value
          };
        } else {
          nft.rarity = {
            rarity: res3.data.rarity,
            type: "Super Rare Bear"
          };
        }
        fetchedNfts.push(nft);
        setNfts([...fetchedNfts]);
      } catch (e) {
        console.log("error", e);
      }
    }
    setNfts(fetchedNfts);
  };

  const retryImg = async (e: any) => {
    await sleeper();
    e.target.src = e.target.src.split("?")[0] + "?" + new Date().getTime();
  };

  const endLoad = (idx: number) => {
    setEndedLoading(c => {
      return [...c, idx];
    });
  };
  return (
    <>
      <NFTResult>
        {nfts.length > 0 ? (
          notEmpty ? (
            nfts.map((e, idx) => {
              return (
                <NFTContainer
                  key={idx}
                  href={`${explorerAddress}nfts/${e.identifier}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <NFTImage
                    src={e.url}
                    onError={retryImg}
                    onLoad={() => endLoad(idx)}
                    style={
                      endedLoading.includes(idx)
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  />
                  <Spinner />
                  <NFTName>
                    {e.name}
                  </NFTName>
                </NFTContainer>
              );
            })
          ) : (
            <Info>You own 0 SEEDS NFTs.</Info>
          )
        ) : notEmpty ? (
          <Spinner />
        ) : (
          <Info>You own 0 SEEDS NFTs.</Info>
        )}
      </NFTResult>
    </>
  );
};

const Info = styled.div`
  font-size: ${fontSize.action};
  justify-self: center;
  align-self: center;
  grid-column: 1 / end;
`;
