import { Card, GridContainerHeight } from "components/StyledComponents";
import { GIF, GIFContainer } from "pages/Home";
import { padding } from "style.css";
import styled from "styled-components";
import gif from "../../assets/img/ClawAnimatedHQ.gif";
import Unlock from "./Unlock";

interface IUnlock {
  callbackRoute: string;
  title?: React.ReactNode;
  lead?: React.ReactNode;
  errorComponent?: React.ReactNode;
  webWalletButtonLabel?: string | undefined;
  walletConnectButtonLabel?: string | undefined;
  ledgerButtonLabel?: string | undefined;
  extensionButtonLabel?: string | undefined;
  ledgerRoute: string;
  walletConnectRoute: string;
}

const StyledUnlock = (props: IUnlock) => {
  return (
    <GridContainerHeight h={"85vh"}>
      <Margin>
        {/* <GIFContainer>
          <GIF src={gif} />
        </GIFContainer> */}
        <Card path={"/"}>
          <Unlock {...props} />
        </Card>
      </Margin>
    </GridContainerHeight>
  );
};

export default StyledUnlock;

const Margin = styled.div`
  justify-self: center;
  /* margin: ${padding.p3}; */
  width: 100%;
  padding: 0 ${padding.p3} 0 ${padding.p3};
  display: grid;
`;
